import * as React from 'react'
import styled from '@emotion/styled'
import Swiper from 'react-id-swiper';
require('../../../node_modules/slick-carousel/slick/slick.css');
require('../../../node_modules/slick-carousel/slick/slick-theme.css');
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import {Component} from "react";

const img_slider_1 = require('../../../assets/images/interior/00_Front.jpg');
const img_slider_2 = require('../../../assets/images/interior/002_Room.jpg');
const img_slider_3 = require('../../../assets/images/interior/003_Room.jpg');
const img_slider_4 = require('../../../assets/images/interior/004_Room.jpg');
const img_slider_5 = require('../../../assets/images/interior/005_Room.jpg');
const img_slider_6 = require('../../../assets/images/interior/006_Room.jpg');
const img_slider_7 = require('../../../assets/images/interior/007_Pantry.jpg');
const img_slider_8 = require('../../../assets/images/interior/008_Room.jpg');
const img_slider_9 = require('../../../assets/images/interior/009_Room.jpg');
const img_slider_10 = require('../../../assets/images/interior/010_Ginbar.jpg');
const img_slider_11 = require('../../../assets/images/interior/011_Mural.jpg');
const img_slider_12 = require('../../../assets/images/V7_101_Gin.jpg');

const settings = {
  dots: false,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 0,
  className: "center",
  centerMode: true,
  infinite: true,
  centerPadding: "60px",
  slidesToShow:2,
  speed: 11000,
  easing:'linear',
  slidesToScroll: 1

};
const StyledSlider = styled(Swiper)`
  display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
`




const StyledCarousel = styled(Carousel)`
width:80%;
margin-left:auto;
margin-right:auto;
height:auto !important;

.carousel .slide{
background:rgba(68,68,68,.01);
}

.carousel .slider.animated{
max-height:582px !important;
}
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
        background:rgba(0,0,0,.1) !important;


    li:nth-child(2){
   min-width:60.3% !important;
   }

   li:nth-child(3){
   min-width:56.9% !important;
   }

   li:nth-child(11){
   min-width:43% !important;
   }

    li:nth-child(13){
   min-width:60.3% !important;
   }



     @media screen and (max-width: 1200px) {
width:100%;
}

      @media screen and (max-width: 960px) {
display:none;
}
z-index:0;



`

const StyledMobileCarousel = styled(Carousel)`
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display:none;
    background:rgba(0,0,0,.1) !important;



li{
background:none !important;
}
     @media screen and (max-width: 960px)
    {
    display:block;
    }


  `



const params = {
  speed:9000,
  watchSlidesVisibility: true,
  loop:true,
  loopedSlides:1.2,
  slidesPerView:1.2,
  spaceBetween: 0,
  autoplay: {
    delay: 1000,
  },


};

export default class InteriorSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
  render() {
    const { photoIndex, isOpen } = this.state;

    return (
<div>
  <StyledCarousel infiniteLoop centerSlidePercentage={80} showIndicators={false} centerMode showStatus={false} showThumbs={false} autoPlay interval={5000} transitionTime={1500} >

    <img src={img_slider_1}/>
    <img src={img_slider_2}/>
    <img src={img_slider_3}/>
    <img src={img_slider_4}/>
    <img src={img_slider_5}/>
    <img src={img_slider_6}/>
    <img src={img_slider_7}/>
    <img src={img_slider_8}/>
    <img src={img_slider_9}/>
    <img src={img_slider_10}/>
    <img src={img_slider_11}/>
  </StyledCarousel>

      <StyledMobileCarousel infiniteLoop dynamicHeight showIndicators={false}  showStatus={false} showThumbs={false} autoPlay interval={5000} transitionTime={600} >
    <img src={img_slider_1}/>
    <img src={img_slider_2}/>
    <img src={img_slider_3}/>
    <img src={img_slider_4}/>
    <img src={img_slider_5}/>
    <img src={img_slider_6}/>
    <img src={img_slider_7}/>
    <img src={img_slider_8}/>
    <img src={img_slider_9}/>
    <img src={img_slider_10}/>
    <img src={img_slider_11}/>
  </StyledMobileCarousel>
</div>



    );
  }
}
