import * as React from 'react'
import { Link } from 'gatsby'
//import { StickyContainer, Sticky } from 'react-sticky';

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import TopDiv from "../components/index/TopDiv";
import CenteredDiv from "../components/index/CenteredDiv";
import RightDiv from "../components/index/RightDiv";
import MidDiv from "../components/index/MidDiv";
import BottomDiv from "../components/index/BottomDiv";
import Interior from "../components/rooms/Interior";
import EasyBooking from "../components/booking/BookingText";
import InteriorSlider from "../components/swipesliders/InteriorSlider";
import styled from '@emotion/styled'
import HeaderDesktop from "../components/HeaderDesktop";
import StudioPlx from "../components/swipesliders/StudioPlx";
import StudioInfo from "../components/rooms/Studio";
import StudioPlusPlx from "../components/swipesliders/StudioPlusPlx";
import StudioPlusInfo from "../components/rooms/StudioPlus";
import SuitePlx from "../components/swipesliders/SuitePlx";
import SuiteInfo from "../components/rooms/Suite";
import Helmet from "react-helmet";





const IndexPage = ( {pathContext: { locale }}) => (
  <IndexLayout path='/interior' locale={locale}>
    <Helmet

      title={'Vilhelm7 Berlin Residences | Interior'}
      meta={[
        { name: 'description', content: 'V7 Vilhelm7 Apartments Hotel Rooms Vacations Berlin cheap in the center of Berlin' },
        { name: 'keywords', content: 'Vilhelm7, Apartments, Hotel, Rooms, Berlin, Vacations, Center'},
        {name: 'page-type', content: 'Hotel, Apartments'},
        { name:'copyright', content:"https://www.vilhelm7.de"},
        { name:'X-UA-Compatible', content:"IE=edge"},


      ]}

    />
    <HeaderDesktop></HeaderDesktop>

    <InteriorSlider></InteriorSlider>

<Interior></Interior>


  </IndexLayout>
)

export default IndexPage
