import * as React from 'react'
import styled from '@emotion/styled'
import { FormattedMessage } from 'react-intl';


const StyledInterior = styled.div`
letter-spacing:1px !important;
  width: 80%;
display: flex;
height:30%;
margin-left:auto;
margin-right:auto;
margin-top:3em;
justify-content: space-between;
font-family: "Theano Didot", Regular;
color:rgb(68, 68, 68);
margin-bottom:4em;

  @media screen and (max-width: 960px) {
display:block;
width:100%;
margin-top:2.2em;

   }


`;

const StyledP = styled.p`
font-family: "Theano Didot", Regular;
font-style: italic;
font-size: 200%;
text-align: right;
line-height: 40px;
letter-spacing: 2px;
color:rgb(68, 68, 68);
width:25%;
height:100%;

  @media screen and (max-width: 960px) {
height:auto;
width:100%;
text-align:center;
margin-bottom:1em;

   }


`;

const Einzug = styled.p`
font-family: "Theano Didot", Regular;
font-size: 1.22em;
line-height: 1.5em;
color:rgb(68, 68, 68);
text-indent: 20px;
color:rgb(68, 68, 68);
margin-bottom:0;

  @media screen and (max-width: 960px) {
height:auto;
width:100%;
text-align:left;

   }
`;

const P = styled.p`
font-family: "Theano Didot", Regular;
font-size: 1.22em;
line-height: 1.5em;
color:rgb(68, 68, 68);
color:rgb(68, 68, 68);
margin-bottom:0;

  @media screen and (max-width: 960px) {
height:auto;
width:100%;
text-align:left;

   }

`;

const Div = styled.div`
width:32%;
height:100%;

  @media screen and (max-width: 960px) {
height:auto;
width:80%;
margin-left:auto;
margin-right:auto;


   }

`

interface InteriorProps {
  className?: string
}

const Interior: React.FC<InteriorProps> = ({ }) => (
  <StyledInterior>
<StyledP>Interior </StyledP>
    <Div>
    <Einzug><FormattedMessage id="rooms_text_1" /> </Einzug>
      <P><FormattedMessage id="rooms_text_2" /> </P>
      <P><FormattedMessage id="rooms_text_3" />  </P>
      <P><FormattedMessage id="rooms_text_4" /></P>
    </Div>
    <Div>
    <Einzug><FormattedMessage id="rooms_text_5" />  </Einzug>
      <P><FormattedMessage id="rooms_text_6" /> </P>

    </Div>



  </StyledInterior>

)

export default Interior
